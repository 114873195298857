@if (profileForm) {
  <form
    [id]="formId"
    [formGroup]="profileForm"
    (submit)="submitForm(profileForm.value)"
    class="d-flex flex-column"
  >
    <h5 class="mb-3 text-uppercase bg-light p-2">
      {{ 'PROFILE_INFO' | translate }}
    </h5>
    <div class="row">
      <!-- MARK: Name and description -->
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
          <label class="form-label" for="profileName"
            >{{ 'PROFILE_NAME' | translate }}*</label
          >
          @if (
            profileForm &&
            profileForm.controls['name'] &&
            !profileForm.controls['name'].pristine &&
            profileForm.controls['name'].hasError('required')
          ) {
            <span class="error">
              {{ 'NAME_IS_REQUIRED' | translate }}
            </span>
          }
          <input
            type="text"
            name="profileName"
            class="form-control form-control-dark"
            formControlName="name"
            (input)="emitPristine()"
          />
        </div>
        <div class="form-group mb-3">
          <label class="form-label" for="description">{{
            'TAGLINE_OR_DESCRIPTION' | translate
          }}</label>
          <input
            type="text"
            name="description"
            class="form-control form-control-dark"
            formControlName="description"
          />
        </div>
      </div>

      <!-- MARK: Owner -->
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
          @if (isCreate) {
            <label class="form-label">{{ 'OWNER' | translate }}</label>
            <input
              type="text"
              class="form-control form-control-dark"
              [value]="currentUser?.displayName"
              disabled
            />
          } @else {
            <label class="form-label" for="owner"
              >{{ 'OWNER' | translate }}*</label
            >
            @if (!profile.owner?.active) {
              <label class="text-info form-label" for="owner">
                {{ 'OWNER_HAS_BEEN_DEACTIVATED' | translate }}
              </label>
            }
            @if (
              profileForm &&
              profileForm.controls['ownerId'] &&
              !profileForm.controls['ownerId'].pristine &&
              profileForm.controls['ownerId'].hasError('required')
            ) {
              <span class="error">
                {{ 'OWNER_IS_REQUIRED' | translate }}
              </span>
            }

            <select
              name="owner"
              class="form-select form-select-dark"
              formControlName="ownerId"
            >
              @if (!profile.owner?.active) {
                <option disabled [ngValue]="null" selected>
                  {{ 'SELECT_NEW_OWNER' | translate }}
                </option>
              }
              @for (user of activeUsers; track user.id) {
                <option
                  [ngValue]="user.id"
                  [selected]="profile.owner?.id === user.id"
                >
                  {{ user.displayName }}
                </option>
              }
            </select>
          }
        </div>
        <div class="form-group">
          @if (showOrganizationDropDown) {
            <label class="form-label" for="organizationUid"
              >{{ 'ORGANIZATION' | translate }}*</label
            >
            @if (
              this.profile.organization && !this.profile.organization.active
            ) {
              <label class="text-info form-label" for="organization">
                {{ 'ORGANIZATION_HAS_BEEN_DELETED' | translate }}
              </label>
            }
            @if (
              profileForm &&
              profileForm.controls['organizationUid'] &&
              !profileForm.controls['organizationUid'].pristine &&
              profileForm.controls['organizationUid'].hasError('required')
            ) {
              <span class="error">
                {{ 'ORGANIZATION_IS_REQUIRED' | translate }}
              </span>
            }

            <app-organization-selection
              [profileId]="profile.id"
              [selectedOrganizationId]="profile.organization?.id"
              (selectionChange)="setOrganization($event)"
            ></app-organization-selection>
          }
        </div>
      </div>
    </div>

    <!-- MARK: Default location -->
    @if (!isCreate) {
      <div class="row">
        <div class="col-12 col-sm-6 mb-3">
          <div class="col form-group">
            <label class="form-label" for="defaultLocation"
              >{{ 'DEFAULT_LOCATION' | translate }}*</label
            >

            <select
              name="defaultLocation"
              class="form-select form-select-dark"
              formControlName="defaultLocationId"
              (change)="updateDefaultLocation($event)"
            >
              @if (!defaultLocation()) {
                <option disabled [value]="null" selected>
                  {{ 'SELECT_DEFAULT_LOCATION' | translate }}
                </option>
              }
              @for (location of locationsStore.locations(); track location.id) {
                <option
                  [value]="location.id"
                  [selected]="location.id === defaultLocation()?.id"
                >
                  {{ location.name }}
                </option>
              }
            </select>
          </div>
          <div class="col-12 card mt-1">
            <div class="card-body d-flex justify-content-between">
              @if (!defaultLocation()) {
                <div>
                  <button
                    class="btn btn-sm btn-outline-primary"
                    (click)="createDefaultLocation()"
                  >
                    {{ 'CREATE_DEFAULT_LOCATION' | translate }}
                  </button>
                </div>
              } @else {
                <div class="">
                  <div class="">
                    {{ defaultLocation()?.streetAddress1 }}
                  </div>
                  @if (
                    defaultLocation()?.streetAddress2 !== undefined ||
                    defaultLocation()?.streetAddress2 !== ''
                  ) {
                    <div class="">
                      {{ defaultLocation()?.streetAddress2 }}
                    </div>
                  }
                  <div class="">
                    {{ defaultLocation()?.zip }}
                    {{ defaultLocation()?.city }}
                  </div>
                  <div>
                    {{ defaultLocation()?.country }}
                  </div>
                </div>
                <div>
                  @if (
                    defaultLocation()?.id &&
                    locationsStore.defaultLocation()?.id ===
                      defaultLocation()?.id
                  ) {
                    <button
                      class="btn btn-sm btn-outline-primary"
                      (click)="editDefaultLocation()"
                    >
                      {{ 'EDIT_LOCATION' | translate }}
                    </button>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
    <!-- <pre>{{ profileForm.value | json }}</pre> -->

    <!-- MARK: Tabs  -->
    @if (!isCreate) {
      <div class="gridCompanyInfo">
        <div class="row py-3">
          <div class="col-12">
            <ul class="nav nav-pills navtab-bg">
              <li class="nav-item">
                <a
                  (click)="currentTab = tabs.PROFILE_SETTINGS"
                  data-toggle="tab"
                  aria-expanded="false"
                  [class.active]="currentTab === tabs.PROFILE_SETTINGS"
                  class="nav-link"
                >
                  {{ 'PROFILE_SETTINGS' | translate }}
                </a>
              </li>

              <li class="nav-item">
                <a
                  (click)="currentTab = tabs.PROFILE_FEATURES"
                  data-toggle="tab"
                  aria-expanded="false"
                  [class.active]="currentTab === tabs.PROFILE_FEATURES"
                  class="nav-link"
                >
                  {{ 'PROFILE_FEATURES' | translate }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  (click)="currentTab = tabs.STORAGE_SUMMARY"
                  data-toggle="tab"
                  aria-expanded="true"
                  [class.active]="currentTab === tabs.STORAGE_SUMMARY"
                  class="nav-link"
                >
                  {{ 'STORAGE_SUMMARY' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- MARK: Profile Settings Tab -->
        @if (currentTab === tabs.PROFILE_SETTINGS) {
          <div class="row p-3">
            <h4>
              {{ 'PROFILE_SETTINGS_WORKSPACE_HEADER' | translate }}
            </h4>
            <table class="w-auto table">
              <tbody>
                <tr>
                  <td>
                    <span ngbTooltip="{{ 'PROFILE_TIMEZONE_TT' | translate }}">
                      {{ 'PROFILE_TIMEZONE' | translate }}
                    </span>
                  </td>
                  <td style="min-width: 10rem">
                    <designage-typeahead
                      class="position-relative"
                      [formSizeSmall]="true"
                      [items]="timezones"
                      [defaultItem]="profileForm.controls.timezone.value"
                      [placeHolderText]="profileForm.controls.timezone.value"
                      [noMatchedItemText]="'NO_ITEMS_FOUND' | translate"
                      (selectItem)="updateTimezone($event)"
                    />
                  </td>
                  <td>
                    <span class="badge bg-black mx-1">
                      GMT {{ timezonePreview() }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      {{ 'PROFILE_DATE_FORMAT' | translate }}
                    </span>
                  </td>
                  <td>
                    <select
                      name="dateFormat"
                      class="form-select form-select-sm form-select-dark"
                      formControlName="dateFormat"
                    >
                      <!-- [selected]="dateFormat.name == profileDateFormat.name" -->
                      @for (format of dateFormats; track format.name) {
                        <option
                          [ngValue]="format"
                          [selected]="format.name === profileDateFormat.name"
                        >
                          {{ format.name }}
                        </option>
                      }
                    </select>
                  </td>
                  <td>
                    <span class="badge bg-black mx-1">
                      {{
                        dateNow
                          | date
                            : this.profileForm.controls.dateFormat.value.short
                            : undefined
                            : timezoneOffset()
                      }}
                    </span>
                    <span class="badge bg-black mx-1">
                      {{
                        dateNow
                          | date
                            : this.profileForm.controls.dateFormat.value.medium
                            : undefined
                            : timezoneOffset()
                      }}
                    </span>
                    <span class="badge bg-black mx-1">
                      {{
                        dateNow
                          | date
                            : this.profileForm.controls.dateFormat.value.full
                            : undefined
                            : timezoneOffset()
                      }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span ngbTooltip="{{ 'PROFILE_TIMEZONE_TT' | translate }}">
                      {{ 'PROFILE_TIME_FORMAT' | translate }}
                    </span>
                  </td>
                  <td>
                    <select
                      class="form-select form-select-sm form-select-dark"
                      formControlName="timeFormat"
                    >
                      @for (timeFormat of timeFormats; track timeFormat.name) {
                        <option
                          [ngValue]="timeFormat"
                          [selected]="
                            timeFormat.name === profileTimeFormat.name
                          "
                        >
                          {{ timeFormat.name }}
                        </option>
                      }
                    </select>
                  </td>
                  <td>
                    <span class="badge bg-black mx-1">
                      {{
                        dateNow
                          | date
                            : this.profileForm.controls.timeFormat.value.short
                            : undefined
                            : timezoneOffset()
                      }}
                    </span>
                    <span class="badge bg-black mx-1">
                      {{
                        dateNow
                          | date
                            : this.profileForm.controls.timeFormat.value.medium
                            : undefined
                            : timezoneOffset()
                      }}
                    </span>
                    <span class="badge bg-black mx-1">
                      {{
                        dateNow
                          | date
                            : this.profileForm.controls.timeFormat.value.full
                            : undefined
                            : timezoneOffset()
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }

        <!-- MARK: Profile Features Tab -->
        @if (currentTab === tabs.PROFILE_FEATURES) {
          <div class="d-flex flex-row w-auto">
            @for (feature of availableFeatures; track feature) {
              <div class="col card m-3">
                <h5 class="card-header p-2 form-check form-switch">
                  <input
                    type="checkbox"
                    class="mx-1 form-check-input"
                    [checked]="isFeatureSelected(feature)"
                    (click)="toggleFeature(feature)"
                  />

                  <div class="d-flex flex-wrap">
                    <label class="ms-2 form-check-label">{{
                      feature | translate
                    }}</label>
                  </div>
                </h5>
                <div class="card-body">
                  {{ feature + '_DESCRIPTION' | translate }}
                </div>
              </div>
            }
          </div>
        }

        <!-- MARK: Storage Summary Tab -->
        @if (currentTab === tabs.STORAGE_SUMMARY) {
          <app-media-storage-summary
            [showNonActive]="true"
            [showButtons]="isSuperAdmin"
          />
        }
      </div>
    }
  </form>
}
